import React, { useState, useMemo, useCallback } from "react";
import useBookingApp from "../../hooks/useBookingApp";
import { useNavigate } from "react-router-dom";
import {
  setBookingTemplate,
  saveTemplate,
} from "../../dispatchers/templateSectionDispatchers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "moment/locale/de";
import "moment/locale/en-gb";
import "moment/locale/zh-cn";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "react-phone-input-2/lib/style.css";
import { Autocomplete, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import moment from "moment";
import countryNames from "../../services/countries.json";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import injectionIcon from "../../Assets/Vector.png";
import setAddCountry from "../../Models/AddCountry/setAddCountry";
import TravelLogo from "../../Assets/travellogo.png";
import Flag from 'react-world-flags'

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

function ReshceduleBooking() {
  const navigate = useNavigate();
  const { templateSectionState, consentState } = useBookingApp();
  const {
    bookingTemplate,
    // travelQuestion,
  } = templateSectionState;
  const travelQuestion = "Where are you going?";
  const { rescheduleBookingModel, currentServiceId } = consentState || {};
  const [expandedSections, setExpandedSections] = useState({});
  const [selectListIndex, setSelectListIndex] = useState(0);
  const [currentCountry, setCurrentCountry] = useState("");
  // const handleExpandClick = (index) => {
  //   setExpandedSections((prev) => ({
  //     ...prev,
  //     [index]: !prev[index],
  //   }));
  // };
  const handleExpandClick = useCallback((index) => {
    setExpandedSections((prev) => ({ ...prev, [index]: !prev[index] }));
  }, []);

  const sampleJSON = [
    {
      groupId: "ac8577fa-9e6d-4ed1-946b-404719887c34",
      order: 1,
      sectionTitle: "Where are you going?",
      isParent: true,
      userControls: [
        {
          userControlId: "00000000-0000-0000-0000-000000000000",
          name: "Starttypingacountry",
          type: 0,
          label: "Start typing a country",
          inline: false,
          required: true,
          value: "",
          listItems: null,
          order: 4,
          parentUserControlId: null,
          isChildControl: false,
        },
      ],
      templateSectionType: 1,
      isRequired: false,
    },
    // {
    //   "groupId": "ac8577fa-9e6d-4ed1-946b-404719887c34",
    //   "order": 1,
    //   "sectionTitle": "Afghanistan",
    //   "isParent": false,
    //   "userControls": [
    //     {
    //       "userControlId": "f1700ee8-1a18-4453-a5fb-720a807ad78e",
    //       "name": "Tripdetails",
    //       "type": 1,
    //       "label": "Trip details",
    //       "inline": false,
    //       "required": true,
    //       "value": "",
    //       "listItems": null,
    //       "order": 2,
    //       "parentUserControlId": null,
    //       "isChildControl": false
    //     },
    //     {
    //       "userControlId": "00000000-0000-0000-0000-000000000000",
    //       "name": "Dateenteringcountry",
    //       "type": 3,
    //       "label": "Date entering country",
    //       "inline": false,
    //       "required": true,
    //       "value": "",
    //       "listItems": null,
    //       "order": 3,
    //       "parentUserControlId": "f1700ee8-1a18-4453-a5fb-720a807ad78e",
    //       "isChildControl": false
    //     },
    //     {
    //       "userControlId": "00000000-0000-0000-0000-000000000000",
    //       "name": "Dateleavingcountry",
    //       "type": 3,
    //       "label": "Date leaving country",
    //       "inline": false,
    //       "required": true,
    //       "value": "",
    //       "listItems": null,
    //       "order": 3,
    //       "parentUserControlId": "f1700ee8-1a18-4453-a5fb-720a807ad78e",
    //       "isChildControl": false
    //     },
    //     {
    //       "userControlId": "00000000-0000-0000-0000-000000000000",
    //       "name": "Travellingby",
    //       "type": 7,
    //       "label": "Travelling by",
    //       "inline": false,
    //       "required": false,
    //       "value": "",
    //       "listItems": [
    //         {
    //           "label": "Aeroplane",
    //           "value": "Aeroplane",
    //           "selected": false
    //         },
    //         {
    //           "label": "Bus",
    //           "value": "Bus",
    //           "selected": true
    //         }
    //       ],
    //       "order": 1,
    //       "parentUserControlId": "f1700ee8-1a18-4453-a5fb-720a807ad78e",
    //       "isChildControl": false
    //     },
    //     {
    //       "userControlId": "00000000-0000-0000-0000-000000000000",
    //       "name": "Destinationtype",
    //       "type": 6,
    //       "label": "Destination type",
    //       "inline": false,
    //       "required": true,
    //       "value": "",
    //       "listItems": [
    //         {
    //           "label": "Urban",
    //           "value": "Urban",
    //           "selected": true
    //         },
    //         {
    //           "label": "Rural",
    //           "value": "Rural",
    //           "selected": true
    //         },
    //         {
    //           "label": "Jungle",
    //           "value": "Jungle",
    //           "selected": true
    //         },
    //         {
    //           "label": "Desert",
    //           "value": "Desert",
    //           "selected": false
    //         },
    //         {
    //           "label": "Coastal",
    //           "value": "Coastal",
    //           "selected": false
    //         },
    //         {
    //           "label": "High altitude",
    //           "value": "High altitude",
    //           "selected": false
    //         },
    //         {
    //           "label": "Safari",
    //           "value": "Safari",
    //           "selected": true
    //         }
    //       ],
    //       "order": 3,
    //       "parentUserControlId": null,
    //       "isChildControl": false
    //     },
    //     {
    //       "userControlId": "00000000-0000-0000-0000-000000000000",
    //       "name": "Purposeoftrip",
    //       "type": 6,
    //       "label": "Purpose of trip",
    //       "inline": false,
    //       "required": true,
    //       "value": "",
    //       "listItems": [
    //         {
    //           "label": "Adventure",
    //           "value": "Adventure",
    //           "selected": false
    //         },
    //         {
    //           "label": "Aid/ healthcare/ charity work",
    //           "value": "Aid/ healthcare/ charity work",
    //           "selected": true
    //         },
    //         {
    //           "label": "Business",
    //           "value": "Business",
    //           "selected": false
    //         },
    //         {
    //           "label": "Cruise",
    //           "value": "Cruise",
    //           "selected": false
    //         },
    //         {
    //           "label": "Driving",
    //           "value": "Driving",
    //           "selected": true
    //         },
    //         {
    //           "label": "Holiday",
    //           "value": "Holiday",
    //           "selected": true
    //         },
    //         {
    //           "label": "Long term",
    //           "value": "Long term",
    //           "selected": false
    //         },
    //         {
    //           "label": "Gap year",
    //           "value": "Gap year",
    //           "selected": true
    //         },
    //         {
    //           "label": "Pilgrimage",
    //           "value": "Pilgrimage",
    //           "selected": false
    //         },
    //         {
    //           "label": "Visiting friends or family",
    //           "value": "Visiting friends or family",
    //           "selected": false
    //         }
    //       ]
    //       ,
    //       "order": 3,
    //       "parentUserControlId": null,
    //       "isChildControl": false
    //     },
    //     {
    //       "userControlId": "00000000-0000-0000-0000-000000000000",
    //       "name": "Accommodationtype",
    //       "type": 6,
    //       "label": "Accommodation type",
    //       "inline": false,
    //       "required": true,
    //       "value": "",
    //       "listItems": [
    //         {
    //           "label": "Hostel/ Airbnb",
    //           "value": "Hostel/ Airbnb",
    //           "selected": true
    //         },
    //         {
    //           "label": "Hostel",
    //           "value": "Hostel",
    //           "selected": true
    //         },
    //         {
    //           "label": "Camping/ camper van",
    //           "value": "Camping/ camper van",
    //           "selected": false
    //         },
    //         {
    //           "label": "Friends or family",
    //           "value": "Friends or family",
    //           "selected": false
    //         }
    //       ],
    //       "order": 3,
    //       "parentUserControlId": null,
    //       "isChildControl": false
    //     },
    //   ],
    //   "templateSectionType": 1,
    //   "isRequired": false
    // },
    // {
    //   "groupId": "ac8577fa-9e6d-4ed1-946b-404719887c34",
    //   "order": 1,
    //   "sectionTitle": "Pakistan",
    //   "isParent": false,
    //   "userControls": [
    //     {
    //       "userControlId": "f1700ee8-1a18-4453-a5fb-720a807ad78e",
    //       "name": "Tripdetails",
    //       "type": 1,
    //       "label": "Trip details",
    //       "inline": false,
    //       "required": true,
    //       "value": "",
    //       "listItems": null,
    //       "order": 2,
    //       "parentUserControlId": null,
    //       "isChildControl": false
    //     },
    //     {
    //       "userControlId": "00000000-0000-0000-0000-000000000000",
    //       "name": "Dateenteringcountry",
    //       "type": 3,
    //       "label": "Date entering country",
    //       "inline": false,
    //       "required": true,
    //       "value": "",
    //       "listItems": null,
    //       "order": 3,
    //       "parentUserControlId": "f1700ee8-1a18-4453-a5fb-720a807ad78e",
    //       "isChildControl": false
    //     },
    //     {
    //       "userControlId": "00000000-0000-0000-0000-000000000000",
    //       "name": "Dateleavingcountry",
    //       "type": 3,
    //       "label": "Date leaving country",
    //       "inline": false,
    //       "required": true,
    //       "value": "",
    //       "listItems": null,
    //       "order": 3,
    //       "parentUserControlId": "f1700ee8-1a18-4453-a5fb-720a807ad78e",
    //       "isChildControl": false
    //     },
    //     {
    //       "userControlId": "00000000-0000-0000-0000-000000000000",
    //       "name": "Travellingby",
    //       "type": 7,
    //       "label": "Travelling by",
    //       "inline": false,
    //       "required": false,
    //       "value": "",
    //       "listItems": [
    //         {
    //           "label": "Aeroplane",
    //           "value": "Aeroplane",
    //           "selected": false
    //         },
    //         {
    //           "label": "Bus",
    //           "value": "Bus",
    //           "selected": true
    //         }
    //       ],
    //       "order": 1,
    //       "parentUserControlId": "f1700ee8-1a18-4453-a5fb-720a807ad78e",
    //       "isChildControl": false
    //     },
    //     {
    //       "userControlId": "00000000-0000-0000-0000-000000000000",
    //       "name": "Destinationtype",
    //       "type": 6,
    //       "label": "Destination type",
    //       "inline": false,
    //       "required": true,
    //       "value": "",
    //       "listItems": [
    //         {
    //           "label": "Urban",
    //           "value": "Urban",
    //           "selected": false
    //         },
    //         {
    //           "label": "Rural",
    //           "value": "Rural",
    //           "selected": false
    //         },
    //         {
    //           "label": "Jungle",
    //           "value": "Jungle",
    //           "selected": false
    //         },
    //         {
    //           "label": "Desert",
    //           "value": "Desert",
    //           "selected": false
    //         },
    //         {
    //           "label": "Coastal",
    //           "value": "Coastal",
    //           "selected": false
    //         },
    //         {
    //           "label": "High altitude",
    //           "value": "High altitude",
    //           "selected": false
    //         },
    //         {
    //           "label": "Safari",
    //           "value": "Safari",
    //           "selected": true
    //         }
    //       ],
    //       "order": 3,
    //       "parentUserControlId": null,
    //       "isChildControl": false
    //     },
    //     {
    //       "userControlId": "00000000-0000-0000-0000-000000000000",
    //       "name": "Purposeoftrip",
    //       "type": 6,
    //       "label": "Purpose of trip",
    //       "inline": false,
    //       "required": true,
    //       "value": "",
    //       "listItems": [
    //         {
    //           "label": "Adventure",
    //           "value": "Adventure",
    //           "selected": false
    //         },
    //         {
    //           "label": "Aid/ healthcare/ charity work",
    //           "value": "Aid/ healthcare/ charity work",
    //           "selected": false
    //         },
    //         {
    //           "label": "Business",
    //           "value": "Business",
    //           "selected": false
    //         },
    //         {
    //           "label": "Cruise",
    //           "value": "Cruise",
    //           "selected": true
    //         },
    //         {
    //           "label": "Driving",
    //           "value": "Driving",
    //           "selected": false
    //         },
    //         {
    //           "label": "Holiday",
    //           "value": "Holiday",
    //           "selected": false
    //         },
    //         {
    //           "label": "Long term",
    //           "value": "Long term",
    //           "selected": false
    //         },
    //         {
    //           "label": "Gap year",
    //           "value": "Gap year",
    //           "selected": false
    //         },
    //         {
    //           "label": "Pilgrimage",
    //           "value": "Pilgrimage",
    //           "selected": false
    //         },
    //         {
    //           "label": "Visiting friends or family",
    //           "value": "Visiting friends or family",
    //           "selected": false
    //         }
    //       ]
    //       ,
    //       "order": 3,
    //       "parentUserControlId": null,
    //       "isChildControl": false
    //     },
    //     {
    //       "userControlId": "00000000-0000-0000-0000-000000000000",
    //       "name": "Accommodationtype",
    //       "type": 6,
    //       "label": "Accommodation type",
    //       "inline": false,
    //       "required": true,
    //       "value": "",
    //       "listItems": [
    //         {
    //           "label": "Hostel/ Airbnb",
    //           "value": "Hostel/ Airbnb",
    //           "selected": true
    //         },
    //         {
    //           "label": "Hostel",
    //           "value": "Hostel",
    //           "selected": true
    //         },
    //         {
    //           "label": "Camping/ camper van",
    //           "value": "Camping/ camper van",
    //           "selected": false
    //         },
    //         {
    //           "label": "Friends or family",
    //           "value": "Friends or family",
    //           "selected": false
    //         }
    //       ],
    //       "order": 3,
    //       "parentUserControlId": null,
    //       "isChildControl": false
    //     },
    //   ],
    //   "templateSectionType": 1,
    //   "isRequired": false
    // },
    {
      groupId: "00000000-0000-0000-0000-000000000000",
      order: 1,
      sectionTitle: "Vaccine history",
      isParent: false,
      userControls: [
        {
          userControlId: "00000000-0000-0000-0000-000000000000",
          name: "Cholera",
          type: 4,
          label: "Cholera",
          inline: false,
          required: true,
          value: "false",
          listItems: null,
          order: 1,
          parentUserControlId: null,
          isChildControl: false,
        },
        {
          userControlId: "00000000-0000-0000-0000-000000000000",
          name: "DiphtheriaTetanusPolio",
          type: 4,
          label: "DTP (Diphtheria, Tetanus, Polio)",
          inline: false,
          required: true,
          value: "false",
          listItems: null,
          order: 1,
          parentUserControlId: null,
          isChildControl: false,
        },
        {
          userControlId: "dd345f98-d719-42b6-8a11-7bd698232f4b",
          name: "Pleasetellusthevaccinenameandtypeofimmunisationyou’vehad",
          type: 1,
          label:
            "Please tell us the vaccine name and type of immunisation you’ve had",
          inline: false,
          required: false,
          value: "",
          listItems: null,
          order: 1,
          parentUserControlId: null,
          isChildControl: false,
        },
        {
          userControlId: "00000000-0000-0000-0000-000000000000",
          name: "Vaccinename",
          type: 7,
          label: "Vaccine name",
          inline: false,
          required: false,
          value: "",
          listItems: [
            {
              label: "Covid",
              value: "Covid",
              selected: false,
            },
            {
              label: "Bug",
              value: "Bug",
              selected: false,
            },
            {
              label: "Error",
              value: "Error",
              selected: false,
            },
            {
              label: "Dose",
              value: "Dose",
              selected: false,
            },
            {
              label: "New",
              value: "New",
              selected: false,
            },
            {
              label: "Vaccine",
              value: "Vaccine",
              selected: false,
            },
          ],
          order: 1,
          parentUserControlId: "dd345f98-d719-42b6-8a11-7bd698232f4b",
          isChildControl: false,
        },
        {
          userControlId: "00000000-0000-0000-0000-000000000000",
          name: "Immunisationtype",
          type: 7,
          label: "Immunisation type",
          inline: false,
          required: false,
          value: "",
          listItems: [
            {
              label: "Its Unknown",
              value: "Its Unknown",
              selected: false,
            },
            {
              label: "Known",
              value: "Known",
              selected: false,
            },
            {
              label: "Still Not Known",
              value: "Still Not Known",
              selected: false,
            },
          ],
          order: 1,
          parentUserControlId: "dd345f98-d719-42b6-8a11-7bd698232f4b",
          isChildControl: false,
        },
        {
          userControlId: "00000000-0000-0000-0000-000000000000",
          name: "Scheduletype",
          type: 7,
          label: "Schedule type",
          inline: false,
          required: false,
          value: "",
          listItems: [
            {
              label: "Unknown",
              value: "Unknown",
              selected: false,
            },
            {
              label: "About to know",
              value: "About to know",
              selected: false,
            },
            {
              label: "Not Known",
              value: "Not Known",
              selected: false,
            },
          ],
          order: 1,
          parentUserControlId: "dd345f98-d719-42b6-8a11-7bd698232f4b",
          isChildControl: false,
        },
        {
          userControlId: "b0a98b79-33bd-4f7c-b046-77b19f3f2e69",
          name: "Pleasegiveussomedetailsaboutthevaccine",
          type: 1,
          label: "Please give us some details about the vaccine ",
          inline: false,
          required: false,
          value: "",
          listItems: null,
          order: 1,
          parentUserControlId: null,
          isChildControl: false,
        },
        {
          userControlId: "00000000-0000-0000-0000-000000000000",
          name: "Dateoflastdose",
          type: 3,
          label: "Date of last dose",
          inline: false,
          required: false,
          value: "",
          listItems: null,
          order: 1,
          parentUserControlId: "b0a98b79-33bd-4f7c-b046-77b19f3f2e69",
          isChildControl: false,
        },
        // {
        //   "userControlId": "00000000-0000-0000-0000-000000000000",
        //   "name": "Numberofdoses",
        //   "type": 0,
        //   "label": "Number of doses",
        //   "inline": false,
        //   "required": false,
        //   "value": "",
        //   "listItems": null,
        //   "order": 1,
        //   "parentUserControlId": "b0a98b79-33bd-4f7c-b046-77b19f3f2e69",
        //   "isChildControl": false
        // },
        {
          userControlId: "00000000-0000-0000-0000-000000000000",
          name: "Numberofdoses",
          type: 7,
          label: "Number of doses",
          inline: false,
          required: false,
          value: "",
          listItems: [
            {
              label: "0",
              value: "0",
              selected: false,
            },
            {
              label: "1",
              value: "1",
              selected: false,
            },
          ],
          order: 1,
          parentUserControlId: "b0a98b79-33bd-4f7c-b046-77b19f3f2e69",
          isChildControl: false,
        },
        {
          userControlId: "00000000-0000-0000-0000-000000000000",
          name: "HepatitisA",
          type: 4,
          label: "Hepatitis A",
          inline: false,
          required: true,
          value: "false",
          listItems: null,
          order: 1,
          parentUserControlId: null,
          isChildControl: false,
        },
        {
          userControlId: "00000000-0000-0000-0000-000000000000",
          name: "HepatitisA+B",
          type: 4,
          label: "Hepatitis A + B",
          inline: false,
          required: true,
          value: "false",
          listItems: null,
          order: 1,
          parentUserControlId: null,
          isChildControl: false,
        },
        {
          userControlId: "00000000-0000-0000-0000-000000000000",
          name: "HepatitisA+Typhoid",
          type: 4,
          label: "Hepatitis A + Typhoid",
          inline: false,
          required: true,
          value: "false",
          listItems: null,
          order: 1,
          parentUserControlId: null,
          isChildControl: false,
        },
        {
          userControlId: "00000000-0000-0000-0000-000000000000",
          name: "HepatitisB",
          type: 4,
          label: "Hepatitis B",
          inline: false,
          required: true,
          value: "false",
          listItems: null,
          order: 1,
          parentUserControlId: null,
          isChildControl: false,
        },
        {
          userControlId: "00000000-0000-0000-0000-000000000000",
          name: "Japaneseencephalitis",
          type: 4,
          label: "Japanese encephalitis",
          inline: false,
          required: true,
          value: "unsure",
          listItems: null,
          order: 1,
          parentUserControlId: null,
          isChildControl: false,
        },
        {
          userControlId: "00000000-0000-0000-0000-000000000000",
          name: "MeningitisACWY",
          type: 4,
          label: "Meningitis ACWY",
          inline: false,
          required: true,
          value: "false",
          listItems: null,
          order: 1,
          parentUserControlId: null,
          isChildControl: false,
        },
        {
          userControlId: "00000000-0000-0000-0000-000000000000",
          name: "Rabies",
          type: 4,
          label: "Rabies",
          inline: false,
          required: true,
          value: "false",
          listItems: null,
          order: 1,
          parentUserControlId: null,
          isChildControl: false,
        },
        {
          userControlId: "00000000-0000-0000-0000-000000000000",
          name: "Tick-borneencephalitis",
          type: 4,
          label: "Tick-borne encephalitis",
          inline: false,
          required: true,
          value: "false",
          listItems: null,
          order: 1,
          parentUserControlId: null,
          isChildControl: false,
        },
        {
          userControlId: "00000000-0000-0000-0000-000000000000",
          name: "Yellowfever",
          type: 4,
          label: "Yellow fever",
          inline: false,
          required: true,
          value: "false",
          listItems: null,
          order: 1,
          parentUserControlId: null,
          isChildControl: false,
        },
      ],
      templateSectionType: 1,
      isRequired: false,
    },
    {
      groupId: "00000000-0000-0000-0000-000000000000",
      order: 1,
      sectionTitle: "About you",
      isParent: false,
      userControls: [
        {
          userControlId: "00000000-0000-0000-0000-000000000000",
          name: "Are you well today",
          type: 5,
          label: "Are you well today",
          inline: false,
          required: true,
          value: "false",
          listItems: null,
          order: 1,
          parentUserControlId: null,
          isChildControl: false,
        },
        {
          userControlId: "00000000-0000-0000-0000-000000000000",
          name: "DoyouhaveanyhealthconditionsorreceivingregulartreatmentorfollowupwithyourGPhospitalspecialist",
          type: 5,
          label:
            "Do you have any health conditions or receiving regular treatment or follow-up with your GP/ hospital/ specialist?",
          inline: false,
          required: true,
          value: "false",
          listItems: null,
          order: 1,
          parentUserControlId: null,
          isChildControl: false,
        },
        {
          userControlId: "00000000-0000-0000-0000-000000000000",
          name: "Doyouyourparentsbrothersisterorchildhaveepilepsyorseizures",
          type: 5,
          label:
            "Do you, your parents, brother, sister, or child, have epilepsy or seizures?",
          inline: false,
          required: true,
          value: "false",
          listItems: null,
          order: 1,
          parentUserControlId: null,
          isChildControl: false,
        },
        {
          userControlId: "00000000-0000-0000-0000-000000000000",
          name: "Haveyouyourparentsbrothersisterorchildeverexperiencedanymentalhealthissuesevenmildanxietyordepression",
          type: 5,
          label:
            "Have you, your parents, brother, sister, or child, ever experienced any mental health issues – even mild anxiety, or depression?",
          inline: false,
          required: true,
          value: "false",
          listItems: null,
          order: 1,
          parentUserControlId: null,
          isChildControl: false,
        },
        {
          userControlId: "00000000-0000-0000-0000-000000000000",
          name: "Doyouhaveaconditionhaveyoutakenmedicationorhadtreatmentinthelast12monthsthatcouldimpairyourimmunesystem",
          type: 5,
          label:
            "Do you have a condition, have you taken medication or had treatment in the last 12 months that could impair your immune system?",
          inline: false,
          required: true,
          value: "false",
          listItems: null,
          order: 1,
          parentUserControlId: null,
          isChildControl: false,
        },
        {
          userControlId: "00000000-0000-0000-0000-000000000000",
          name: "Haveyoueverhadsurgery",
          type: 5,
          label: "Have you ever had surgery?",
          inline: false,
          required: true,
          value: "false",
          listItems: null,
          order: 1,
          parentUserControlId: null,
          isChildControl: false,
        },
        {
          userControlId: "00000000-0000-0000-0000-000000000000",
          name: "Haveyoueverhadatravelrelatedillnessorinjurythatrequiredtreatmentoratriptothehospital?",
          type: 5,
          label:
            "Have you ever had a travel-related illness or injury that required treatment or a trip to the hospital?",
          inline: false,
          required: true,
          value: "false",
          listItems: null,
          order: 1,
          parentUserControlId: null,
          isChildControl: false,
        },
        {
          userControlId: "42b9ac35-f41b-4c68-9363-35cd81ead76d",
          name: "Doyouhaveanyallergies",
          type: 5,
          label: "Do you have any allergies?",
          inline: false,
          required: true,
          value: "false",
          listItems: null,
          order: 1,
          parentUserControlId: null,
          isChildControl: false,
        },
        {
          userControlId: "00000000-0000-0000-0000-000000000000",
          name: "Tellusaboutyourallergies",
          type: 0,
          label: "Tell us about your allergies",
          inline: false,
          required: true,
          value: "",
          listItems: null,
          order: 1,
          parentUserControlId: "42b9ac35-f41b-4c68-9363-35cd81ead76d",
          isChildControl: false,
        },
        {
          userControlId: "00000000-0000-0000-0000-000000000000",
          name: "Haveyouoranyoneinfamilyeverhadaseverereactiontoavaccineormalariamedication",
          type: 5,
          label:
            "Have you or anyone in your family, ever had a severe reaction to a vaccine or malaria medication?",
          inline: false,
          required: true,
          value: "false",
          listItems: null,
          order: 1,
          parentUserControlId: null,
          isChildControl: false,
        },
        {
          userControlId: "00000000-0000-0000-0000-000000000000",
          name: "Areyoupregnantorplanningapregnancy",
          type: 5,
          label: "Are you pregnant or planning a pregnancy?",
          inline: false,
          required: true,
          value: "false",
          listItems: null,
          order: 1,
          parentUserControlId: null,
          isChildControl: false,
        },
        {
          userControlId: "00000000-0000-0000-0000-000000000000",
          name: "Areyoutakinganymedications",
          type: 5,
          label: "Are you taking any medications?",
          inline: false,
          required: true,
          value: "true",
          listItems: null,
          order: 1,
          parentUserControlId: null,
          isChildControl: false,
        },
        {
          userControlId: "00000000-0000-0000-0000-000000000000",
          name: "Haveyouhadanyvaccinesinthelast4weeks",
          type: 5,
          label: "Have you had any vaccines in the last 4 weeks?",
          inline: false,
          required: true,
          value: "true",
          listItems: null,
          order: 1,
          parentUserControlId: null,
          isChildControl: false,
        },
      ],
      templateSectionType: 1,
      isRequired: false,
    },
  ];
  const [inputValues, setInputValues] = useState(bookingTemplate);

  const handleFormChange = (
    index,
    event,
    type,
    sectionTitle,
    listItemIndex
  ) => {
    let value;
    console.log("innjj");
    if (type === 0) {
      value = event?.target?.value;
    } else if (type === 3) {
      const date = moment(event).format("DD-MM-YYYY"); // Convert to string format
      value = date;
    } else if (type === 4 || type === 5 || type === 7) {
      value = event?.target?.value;
    } else if (type === 6) {
      value = event?.target?.checked;
    }
    console.log(event?.target?.value);
    // Use functional state update to ensure latest state is used
    setInputValues((prevValues) => {
      const updatedTemplate = prevValues.map((section) => ({
        ...section,
        userControls: section.userControls.map((control) => ({
          ...control,
          listItems: control.listItems ? [...control.listItems] : undefined, // Ensure immutability
        })),
      }));

      const section = updatedTemplate.find(
        (sec) => sec.sectionTitle === sectionTitle
      );

      if (section) {
        const control = section.userControls[index];

        if (control) {
          if (control.type === 6) {
            control.listItems = control.listItems.map((item, idx) =>
              idx === listItemIndex ? { ...item, selected: value } : item
            );
            control.value = control.listItems
              .filter((item) => item.selected)
              .map((item) => item.value)
              .join(",");
          } else if (control.type === 7) {
            console.log(control);
            control.value = value;
            control.listItems = control.listItems.map((item) => ({
              ...item,
              selected: item.value === value,
            }));
          } else {
            control.value = value;
          }
        }
      }

      return updatedTemplate; // Ensure state update
    });
  };
  function handlecheck() {
    console.log("jhjgjgh");
  }
  function resetTextBoxControls() {
    const updatedTemplate = inputValues?.map((section) => ({
      ...section,
      userControls: section?.userControls?.map((control) => ({ ...control })),
    }));

    const medicalHistorySection = updatedTemplate?.find(
      (section) => section?.sectionTitle === "Medical History"
    );

    medicalHistorySection?.userControls?.forEach((control) => {
      const parentControl = medicalHistorySection?.userControls?.find(
        (item) => item?.userControlId === control?.parentUserControlId
      );
      if (parentControl?.type === 5 && parentControl?.value === "false") {
        control.value = "";
      }
    });

    setInputValues(updatedTemplate);
    return updatedTemplate;
  }

  const formatBookingDateWithOrdinal = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();

    // Get ordinal suffix
    const getOrdinalSuffix = (day) => {
      if (day > 3 && day < 21) return "th";
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };

    return `${date.toLocaleDateString("en-US", {
      weekday: "long",
    })}, ${day}${getOrdinalSuffix(day)} ${date.toLocaleDateString("en-US", {
      month: "long",
    })}`;
  };

  function handleSaveTemplate() {
    const updatedInputValues = resetTextBoxControls();
    const templateModel = {
      ClinicalServiceId: currentServiceId,
      TemplateSections: updatedInputValues,
    };
    // console.log(templateModel)
    setBookingTemplate(inputValues);
    saveTemplate(templateModel).then((data) => {
      navigate(`/travelDetailsSummary`);
    });
  }

  const handleRemoveCountry = (sectionTitle) => {
    setInputValues(
      inputValues.filter((section) => section.sectionTitle !== sectionTitle)
    );
  };

  const handleAddDestination = () => {
    if (!currentCountry) return console.error("No country selected");

    // Check if the country is already in the inputValues
    const isAlreadyAdded = inputValues.some(
      (section) => section.sectionTitle === currentCountry
    );

    if (isAlreadyAdded) {
      return console.warn("Country already added");
    }

    const groupId = inputValues[0]?.groupId || "default-group-id";
    const newCountry = new setAddCountry(groupId, currentCountry);

    setInputValues((prevInputValues) => {
      const updatedInputValues = [...prevInputValues];
      updatedInputValues.splice(1, 0, newCountry);
      return updatedInputValues;
    });
  };

  return (
    <div className="user_details_main_wrapper">
      <div className="pharmacy-logo-wrapper">
        <img className="pharmacy-logo" src={TravelLogo} alt="pharmacy-logo" />
      </div>
      <h1 className="reshcedule-booking-header">Travel Clinic Appointment</h1>
      <div className="form-group user_control_wrapper">
        <h4>Personal</h4>
        <div className="inline-not-grid">
          <div className="inline-not">
            <div
              style={{ border: "#00ca69" }}
              className="personal_info_wrapper toggle-only"
            >
              <label className="user_controls_label ">Name</label>
              <div className="personal_info_value">
                <span>
                  {`${rescheduleBookingModel[0]?.patientName}` || "-"}
                </span>
              </div>
            </div>
          </div>
          <div className="inline-not">
            <div
              style={{ border: "#00ca69" }}
              className="personal_info_wrapper toggle-only"
            >
              <label className="user_controls_label ">Date of birth</label>
              <div className="personal_info_value">
                <span>{`${formatBookingDateWithOrdinal(
                  rescheduleBookingModel[0]?.dob
                ) || "-"
                  }`}</span>
              </div>
            </div>
          </div>
          <div className="inline-not">
            <div
              style={{ border: "#00ca69" }}
              className="personal_info_wrapper toggle-only"
            >
              <label className="user_controls_label ">Address</label>
              <div className="personal_info_value">
                <span>{"-"}</span>
              </div>
            </div>
          </div>
          <div className="inline-not">
            <div
              style={{ border: "#00ca69" }}
              className="personal_info_wrapper toggle-only"
            >
              <label className="user_controls_label ">GP</label>
              <div className="personal_info_value">
                <span>{`${rescheduleBookingModel[0]?.patientName || "-"
                  }`}</span>
              </div>
            </div>
          </div>
          <div className="inline-not">
            <div
              style={{ border: "#00ca69" }}
              className="personal_info_wrapper toggle-only"
            >
              <label className="user_controls_label">Booking Date</label>
              <div className="personal_info_value">
                <span>{`${formatBookingDateWithOrdinal(
                  rescheduleBookingModel[0]?.dateOfIntervention
                ) || "-"
                  }`}</span>
              </div>
            </div>
          </div>
          <div className="inline-not">
            <div
              style={{ border: "#00ca69" }}
              className="personal_info_wrapper toggle-only"
            >
              <label className="user_controls_label">Pharmacy</label>
              <div className="personal_info_value">
                <span className="personal_info_value">{`${rescheduleBookingModel[0]?.pharmacyName || "-"
                  }`}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {inputValues?.map((section, index) => {
        const isExpanded = expandedSections[index] || false;
        const country = countryNames.find(
          (country) => country.value === section.sectionTitle
        );
        // if (!country) return null; // Hide the section if the title isn't in countryNames

        return (
          <div
            className={`form-group user_control_wrapper ${country ? "isCountrySection" : ""
              }`}
            key={index}
          >
            <div>
              <div className="booking-sub-header">
                <div className="booking-sub-header-logo-wrapper">
                  {country ? (
                    <Flag
                      code={country.code}
                      alt={country.name}
                      className="booking-sub-header-logo"
                    />
                  ) : (
                    <img
                      className="booking-sub-header-logo"
                      src={injectionIcon}
                      alt="injection-icon"
                    />
                  )}
                  <h4>{section?.sectionTitle}</h4>
                </div>
                <div className="">
                  {isExpanded ? "close" : "open"}
                  <ExpandMore
                    expand={isExpanded}
                    onClick={() => handleExpandClick(index)}
                    aria-expanded={isExpanded}
                    aria-label="show more"
                  >
                    <ExpandMoreIcon />
                  </ExpandMore>
                  {country && (
                    <>
                      {"remove "}
                      <CloseIcon
                        onClick={() =>
                          handleRemoveCountry(section.sectionTitle)
                        }
                      />
                    </>
                  )}
                </div>
              </div>
              <Collapse
                in={isExpanded}
                timeout="auto"
                unmountOnExit
                className="collapse-content"
              >
                {section?.userControls?.map((control, index) => {
                  if (control.type === 0) {
                    return (
                      <React.Fragment key={`${control.userControlId}-${index}`}>
                        {section.sectionTitle === travelQuestion ? (
                          <div className="template_control">
                            <div className="user_controls_textField">
                              <Autocomplete
                                id={control?.name}
                                options={countryNames}
                                getOptionLabel={(option) => option.name}
                                value={
                                  countryNames.find(
                                    (country) =>
                                      country.value === control?.value
                                  ) || null
                                }
                                onChange={(event, newValue) => {
                                  setCurrentCountry(newValue?.value || "");
                                  // handleFormChange(
                                  //   index,
                                  //   { target: { value: newValue?.value || "" } },
                                  //   control?.type,
                                  //   section?.sectionTitle
                                  // );
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder={control.label}
                                    required={control?.required}
                                    fullWidth
                                  />
                                )}
                              />
                              <button
                                type="submit"
                                className="section_save_button"
                                onClick={handleAddDestination}
                              >
                                Add Destination
                              </button>
                            </div>
                          </div>
                        ) : (
                          <div className="template_control-inl">
                            <div className="user_controls_textField doses_text_Field">
                              {control?.parentUserControlId &&
                                section.userControls.some(
                                  (parentControl) =>
                                    parentControl.userControlId ===
                                    control.parentUserControlId &&
                                    parentControl.value === "true"
                                ) && (
                                  <TextField
                                    id={control?.name}
                                    variant="outlined"
                                    error={control?.required && !control?.value}
                                    required={control?.required}
                                    value={control?.value || ""}
                                    placeholder={control.label}
                                    onChange={(event) =>
                                      handleFormChange(
                                        index,
                                        event,
                                        control?.type,
                                        section?.sectionTitle
                                      )
                                    }
                                  />
                                )}
                            </div>
                          </div>
                        )}
                      </React.Fragment>
                    );
                  }
                  if (control.type === 1) {
                    if (
                      control?.parentUserControlId &&
                      section.userControls.some(
                        (parentControl) =>
                          parentControl.userControlId ===
                          control.parentUserControlId &&
                          parentControl.value === "true"
                      )
                    ) {
                      return (
                        <React.Fragment
                          key={`${control.userControlId}-${index}`}
                        >
                          <div className="switch-content-reports-outer toggle-only">
                            <label className="user_controls_seperate_label">
                              {control?.label}
                            </label>
                          </div>
                        </React.Fragment>
                      );
                    }
                  }
                  if (control.type === 3) {
                    const isCountrySection = countryNames.find(
                      (country) => country.value === section.sectionTitle
                    );
                    const isVaccineHistorySection =
                      section.sectionTitle === "Vaccine history";

                    if (
                      isCountrySection ||
                      (isVaccineHistorySection &&
                        control?.parentUserControlId &&
                        section.userControls.some(
                          (parentControl) =>
                            parentControl.userControlId ===
                            control.parentUserControlId &&
                            parentControl.value === "true"
                        ))
                    ) {
                      return (
                        <React.Fragment
                          key={`${control.userControlId}-${index}`}
                        >
                          <div className="type-dropdown">
                            <div
                              style={{
                                marginLeft: "10px",
                                marginBottom: "15px",
                              }}
                            >
                              <LocalizationProvider
                                dateAdapter={AdapterMoment}
                                adapterLocale="en-gb"
                              >
                                <DemoContainer
                                  sx={{ minWidth: 250 }}
                                  components={["DatePicker"]}
                                >
                                  <DatePicker
                                    required={control?.required}
                                    label={control?.label}
                                    value={
                                      control?.value
                                        ? moment(control?.value, "DD-MM-YYYY")
                                        : null
                                    } // Ensure correct parsing
                                    onChange={(event) =>
                                      handleFormChange(
                                        index,
                                        event,
                                        control?.type,
                                        section?.sectionTitle
                                      )
                                    }
                                    format="DD-MM-YYYY"
                                  />
                                </DemoContainer>
                              </LocalizationProvider>
                            </div>
                          </div>
                        </React.Fragment>
                      );
                    } else {
                      return null;
                    }
                  }
                  if (control.type === 4) {
                    return (
                      <React.Fragment key={`${control.userControlId}-${index}`}>
                        <div className="radio_buttons_outer toggle-only">
                          <label className="user_controls_label antSwitchLabel">
                            {control?.label}
                          </label>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={control.value}
                            onChange={(event) =>
                              handleFormChange(
                                index,
                                event,
                                control?.type,
                                section?.sectionTitle
                              )
                            }
                          >
                            <FormControlLabel
                              value="true"
                              className="radio_button_wrapper"
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              value="false"
                              className="radio_button_wrapper"
                              control={<Radio />}
                              label="No"
                            />
                            <FormControlLabel
                              value="unsure"
                              className="radio_button_wrapper"
                              control={<Radio />}
                              label="Unsure"
                            />
                          </RadioGroup>
                        </div>
                      </React.Fragment>
                    );
                  }
                  if (control.type === 5) {
                    return (
                      <React.Fragment key={`${control.userControlId}-${index}`}>
                        <div className="radio_buttons_outer toggle-only">
                          <div className="radio_buttons_label">
                            {`${index + 1} . `}
                            <label>{control?.label}</label>
                          </div>
                          <div>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              value={control.value}
                              onChange={(event) =>
                                handleFormChange(
                                  index,
                                  event,
                                  control?.type,
                                  section?.sectionTitle
                                )
                              }
                            >
                              <FormControlLabel
                                value="true"
                                className="radio_button_wrapper"
                                control={<Radio />}
                                label="Yes"
                              />
                              <FormControlLabel
                                value="false"
                                className="radio_button_wrapper"
                                control={<Radio />}
                                label="No"
                              />
                            </RadioGroup>
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  }
                  if (control.type === 6) {
                    return (
                      <React.Fragment key={`${control.userControlId}-${index}`}>
                        <div className="switch-content-reports-outer toggle-only">
                          <div>
                            <label className="user_controls_label antSwitchLabel">
                              {control?.label}
                            </label>
                            <FormGroup>
                              {control.listItems.map((item, listItemIndex) => (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      value={item.value}
                                      checked={item.selected}
                                      onChange={(event) =>
                                        handleFormChange(
                                          index,
                                          event,
                                          control?.type,
                                          section?.sectionTitle,
                                          listItemIndex
                                        )
                                      }
                                    />
                                  }
                                  label={item.label}
                                />
                              ))}
                            </FormGroup>
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  }
                  if (control.type === 7) {
                    if (
                      control?.parentUserControlId &&
                      section.userControls.some(
                        (parentControl) =>
                          parentControl.userControlId ===
                          control.parentUserControlId &&
                          parentControl.value === "true"
                      )
                    ) {
                      const selectedValue =
                        control.listItems.find((item) => item.selected)
                          ?.value || "";
                      return (
                        <React.Fragment
                          key={`${control.userControlId}-${index}`}
                        >
                          <div className="type-dropdown">
                            <FormControl sx={{ m: 1, minWidth: 240 }}>
                              <InputLabel id={`helper-label-${index}`}>
                                {control.label}
                              </InputLabel>
                              <Select
                                // defaultValue={control.value}
                                labelId={`select-label-${index}`}
                                id={`select-helper-${index}`}
                                value={control.value}
                                label="Age"
                                onChange={(event) =>
                                  handleFormChange(
                                    index,
                                    event,
                                    control?.type,
                                    section?.sectionTitle
                                  )
                                }
                              >
                                {control.listItems.map((item, itemIndex) => (
                                  <MenuItem
                                    value={item.value}
                                    name={item.value}
                                    key={itemIndex}
                                    data-index={itemIndex}
                                  >
                                    {item.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </div>
                        </React.Fragment>
                      );
                    }
                  }
                })}
                {/* {section.sectionTitle !== travelQuestion && (
                  <button
                    type="submit"
                    className="section_save_button"
                    onClick={handleSaveTemplateSection}
                  >
                    Save
                  </button>
                )} */}
              </Collapse>
            </div>
          </div>
        );
      })}
      <div className="booking_details_footer">
        <button
          type="submit"
          className="template_save_button"
          onClick={handleSaveTemplate}
        >
          Complete
        </button>
      </div>
    </div>
  );
}
export default ReshceduleBooking;
