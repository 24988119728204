import React, { useState, useEffect } from "react";
import { Tabs, Tab } from "@mui/material";
import ReshceduleBooking from "../UserAuthentication/ReshceduleBooking";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'; // For Reschedule
import TravelExploreIcon from '@mui/icons-material/TravelExplore'; // For Travel Details
import CalendarBookingControl from "./CalendarBookingControl";
import { useNavigate } from "react-router-dom";
import { cancelAppointment, setIsPatientAuthenticated } from "../../dispatchers/consentDispatchers";
import useBookingApp from "../../hooks/useBookingApp";
import Modal from "react-modal";
import CustomLoader from "../../Components/controls/CustomLoader";
import { GetPharmacyBookingById, setInfectionServayData } from "../../dispatchers/templateSectionDispatchers";

function ReshceduleBookingTabs() {
    const { consentState, templateSectionState } = useBookingApp();
    const { currentServiceId, isBookingCancel, rescheduleBookingModel, pharmacyServiceSettings } = consentState || {};
    const { PrivateServices, pharmacyId } = templateSectionState || {};
    const [activeTab, setActiveTab] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [canCancel, setCanCancel] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        if (rescheduleBookingModel.length > 0 && pharmacyServiceSettings) {
            canCancelBooking();
        }
    }, [rescheduleBookingModel, pharmacyServiceSettings]);

    const RenderSubTabs = () => {
        switch (activeTab) {
            case 0:
                return (
                    <ReshceduleBooking />
                );
            case 1:
                return (
                    <CalendarBookingControl />
                );
            default:
                return (
                    <ReshceduleBooking />
                );
        }
    };

    const handleChange = async (event, newValue) => {
        setActiveTab(newValue);
        switch (newValue) {
            case 0:
                // Render Data Headers.apply.....
                break;
            case 1:
                getServiceByServiceID();
                break;
            default:
                // Render Data Headers.apply.....
                break;
        }
    };

    const getServiceByServiceID = () => {
        const matchedService = PrivateServices.find(service => service._id === rescheduleBookingModel[0]?.serviceID);
        setInfectionServayData(matchedService);
        GetPharmacyBookingById({
            pharmacyId: pharmacyId,
            serviceID: matchedService?._id,
        })
    };

    const handleCancel = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const canCancelBooking = () => {
        // Get the cancellation notice period from pharmacyServiceSettings
        const cancellationNoticeDays = parseInt(pharmacyServiceSettings?.onlineBookings.general.cancellationsNotice, 10);
        // Get the intervention date from rescheduleBookingModel
        const interventionDate = new Date(rescheduleBookingModel[0]?.dateOfIntervention);
        const currentDate = new Date();
        // Calculate the difference in days
        const timeDifference = interventionDate - currentDate;
        const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

        if (daysDifference >= cancellationNoticeDays) {
            setCanCancel(true);
        } else {
            setCanCancel(false);
        }
    };

    const cancelBooking = () => {
        setIsModalOpen(false);
        cancelAppointment(currentServiceId)
            .then(() => {
                navigate(`/cancelation`);
                setIsPatientAuthenticated(false);
            })
            .catch((error) => {
                console.error("Error canceling appointment:", error);
            });
    };


    return (
        <>
            {isBookingCancel ? (
                <div className="cancel-loading-wrapper">
                    <CustomLoader />
                </div>
            ) : (
                <div className="reshcedule_tab_wrapper">
                    {/* <Tabs
                        value={activeTab}
                        onChange={handleChange}
                        aria-label="Tabs"
                        TabIndicatorProps={{
                            style: {
                                backgroundColor: "black",
                            },
                        }}
                    >
                        <Tab
                            icon={<TravelExploreIcon sx={{ fontSize: '18px' }} />}
                            iconPosition="start"
                            label={<span className={`tabs_text`}>Travel Details</span>}
                        />
                        <Tab
                            icon={<CalendarTodayIcon sx={{ fontSize: '18px' }} />}
                            iconPosition="start"
                            label={<span className={`tabs_text`}>Reschedule</span>}
                        />
                        {canCancel && (
                            <div className="cancelation_btn_wrapper">
                                <button
                                    type="submit"
                                    className="nhsuk-button cancel-booking-btn"
                                    onClick={handleCancel}
                                >
                                    Cancel Appointment
                                </button>
                            </div>
                        )}
                    </Tabs> */}
                    <div className="">
                        <RenderSubTabs />
                    </div>

                    <Modal
                        isOpen={isModalOpen}
                        onRequestClose={closeModal}
                        contentLabel="Cancel Booking Modal"
                        className="modal-content"
                        overlayClassName="modal-overlay"
                    >
                        <div className="cancel-appointment-main-wrapper">
                            <div className="modal-body-content">
                                <span>
                                    <img
                                        src="https://img.icons8.com/3d-fluency/94/high-priority.png"
                                        alt="high-priority"
                                        className="cancel-icon" />
                                    Are you sure you want to cancel this booking?
                                </span>
                                <p>
                                    This action cannot be undone.
                                    If you proceed, your appointment will be canceled, and you may need to reschedule.
                                </p>
                            </div>
                            <div className="modal-footer-btns">
                                <button onClick={cancelBooking}>Yes</button>
                                <button onClick={closeModal}>No</button>
                            </div>
                        </div>
                    </Modal>
                </div>
            )}
        </>
    );
}
export default ReshceduleBookingTabs;