import { v4 as uuidv4 } from "uuid";

class setAddCountry {
  constructor(groupId, sectionTitle) {
    this.groupId = groupId;
    this.order = 1;
    this.sectionTitle = sectionTitle;
    this.isParent = false;
    this.userControls = [
      {
        userControlId: "f1700ee8-1a18-4453-a5fb-720a807ad78e",
        name: "Tripdetails",
        type: 1,
        label: "Trip details",
        inline: false,
        required: true,
        value: "true",
        listItems: null,
        order: 2,
        parentUserControlId: null,
        isChildControl: false,
      },
      {
        userControlId: "00000000-0000-0000-0000-000000000000",
        name: "Dateenteringcountry",
        type: 3,
        label: "Date entering country",
        inline: false,
        required: true,
        value: "",
        listItems: null,
        order: 3,
        parentUserControlId: "f1700ee8-1a18-4453-a5fb-720a807ad78e",
        isChildControl: false,
      },
      {
        userControlId: "00000000-0000-0000-0000-000000000000",
        name: "Dateleavingcountry",
        type: 3,
        label: "Date leaving country",
        inline: false,
        required: true,
        value: "",
        listItems: null,
        order: 3,
        parentUserControlId: "f1700ee8-1a18-4453-a5fb-720a807ad78e",
        isChildControl: false,
      },
      {
        userControlId: "00000000-0000-0000-0000-000000000000",
        name: "Travellingby",
        type: 7,
        label: "Travelling by",
        inline: false,
        required: false,
        value: "Bus",
        listItems: [
          {
            label: "Aeroplane",
            value: "Aeroplane",
            selected: false,
          },
          {
            label: "Bus",
            value: "Bus",
            selected: true,
          },
        ],
        order: 1,
        parentUserControlId: "f1700ee8-1a18-4453-a5fb-720a807ad78e",
        isChildControl: false,
      },
      {
        userControlId: "00000000-0000-0000-0000-000000000000",
        name: "Destinationtype",
        type: 6,
        label: "Destination type",
        inline: false,
        required: true,
        value: "",
        listItems: [
          {
            label: "Urban",
            value: "Urban",
            selected: false,
          },
          {
            label: "Rural",
            value: "Rural",
            selected: false,
          },
          {
            label: "Jungle",
            value: "Jungle",
            selected: false,
          },
          {
            label: "Desert",
            value: "Desert",
            selected: false,
          },
          {
            label: "Coastal",
            value: "Coastal",
            selected: false,
          },
          {
            label: "High altitude",
            value: "High altitude",
            selected: false,
          },
          {
            label: "Safari",
            value: "Safari",
            selected: false,
          },
        ],
        order: 3,
        parentUserControlId: null,
        isChildControl: false,
      },
      {
        userControlId: "00000000-0000-0000-0000-000000000000",
        name: "Purposeoftrip",
        type: 6,
        label: "Purpose of trip",
        inline: false,
        required: true,
        value: "",
        listItems: [
          {
            label: "Adventure",
            value: "Adventure",
            selected: false,
          },
          {
            label: "Aid/ healthcare/ charity work",
            value: "Aid/ healthcare/ charity work",
            selected: false,
          },
          {
            label: "Business",
            value: "Business",
            selected: false,
          },
          {
            label: "Cruise",
            value: "Cruise",
            selected: false,
          },
          {
            label: "Driving",
            value: "Driving",
            selected: false,
          },
          {
            label: "Holiday",
            value: "Holiday",
            selected: false,
          },
          {
            label: "Long term",
            value: "Long term",
            selected: false,
          },
          {
            label: "Gap year",
            value: "Gap year",
            selected: false,
          },
          {
            label: "Pilgrimage",
            value: "Pilgrimage",
            selected: false,
          },
          {
            label: "Visiting friends or family",
            value: "Visiting friends or family",
            selected: false,
          },
        ],
        order: 3,
        parentUserControlId: null,
        isChildControl: false,
      },
      {
        userControlId: "00000000-0000-0000-0000-000000000000",
        name: "Accommodationtype",
        type: 6,
        label: "Accommodation type",
        inline: false,
        required: true,
        value: "",
        listItems: [
          {
            label: "Hostel/ Airbnb",
            value: "Hostel/ Airbnb",
            selected: false,
          },
          {
            label: "Hostel",
            value: "Hostel",
            selected: false,
          },
          {
            label: "Camping/ camper van",
            value: "Camping/ camper van",
            selected: false,
          },
          {
            label: "Friends or family",
            value: "Friends or family",
            selected: false,
          },
        ],
        order: 3,
        parentUserControlId: null,
        isChildControl: false,
      },
    ];
    this.templateSectionType = 1;
    this.isRequired = false;
  }
}

export default setAddCountry;
